<template>
    <div>
        <div class="scrlg_dtl_ttl">
            <h1 v-if="tournament_detail.name">{{tournament_detail.name}}</h1>
        </div>
        <div class="scrlg_dtl_prnxt">
            <ul>
                <li>
                    <router-link :to="{ name:'football_league_detail' , params: { slug: $route.params.league, leagueId: $route.params.leagueId, date: previous_date }}"
                                 v-if="previous_date">
                        <img alt="Previous" :src="require('@/assets/images/slider_prev_arrow.webp')">
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name:'football_league_detail' , params: { slug: $route.params.league, leagueId: $route.params.leagueId, date: future_date }}"
                                 v-if="future_date">
                        <img alt="Next" :src="require('@/assets/images/slider_next_arrow.webp')">
                    </router-link>
                </li>
            </ul>
        </div>
        <div v-if="tournament.length">
            <div>
                <div class="live_center_fixtured_list vevent">
                    <h2 class="header-cg-48378"><a href="javascript:;">{{$route.params.date| formatDate2('MM YYYY')}}</a></h2>
                    <div class="cg-matches-48378 match_table_content adr" v-for="matches in tournament[0].matches" :key="matches.id">
                        <div class="live_center_fixtured_row">
                            <div class="live_center_fixtured_timeNew">
                            <span class="ftb_match_upcoming" v-if="matches.status == 'Result Only'">
                                    R.O
                                </span>
                                <span class="ftb_match_upcoming" v-if="matches.status == 'NSY'">
                                    NSY
                                </span>
                                <span class="ftb_match_live"
                                      v-if="((matches.status == '1st Half' || matches.status == 'Half Time' || matches.status == '2nd Half'|| matches.status=='ET 1st Half' || matches.status=='ET 2nd Half' || matches.status=='Penalty' || matches.status=='inprogress') && (matches.currentPeriod !='Penalty'))">
                                    {{match.minutes}}'
                                </span>
                                <span class="ftb_match_live"
                                      v-if="(matches.status=='inprogress' && match.currentPeriod =='Penalty')">
                                    PEN
                                </span>
                                <span class="ftb_match_ft"
                                      v-if="(matches.status == 'Finished' && matches.minutes != 'Finished AP')">
                                    FT
                                </span>
                                <span class="ftb_match_ft"
                                      v-if="(matches.status == 'Finished' && matches.minutes == 'Finished AP')">
                                    Fi. AP
                                </span>
                                <span class="ftb_match_ft" v-if="matches.status == 'Finished AET'">
                                    AET
                                </span>
                            </div>
                            <div class="live_center_fixtured_tvlogo mth_lst_mobile_col"></div>
                            <div class="live_center_fixtured_mic mth_lst_mobile_col"></div>
                            <div class="live_center_fixtured_name_result">
                                <router-link
                                        :to="{ name: 'match_detail.overview', params: { slug: $slugify(tournament[0].name + ' ' + $getSafe(()=>$filterTeam(matches.competitors, true).name) + ' vs ' + $getSafe(()=>$filterTeam(matches.competitors, false).name)), date: $route.params.date, matchId: matches.id } }">
                                    <div class="live_center_fixtured_name">
                                        <h5>
                                            <span class="fixtured_home_team_name">{{matches.competitors[0].isHome==true?matches.competitors[0].name:matches.competitors[1].name}}</span>
                                            <span class="fixtured_home_team_name_mobile">{{matches.competitors[0].isHome==true?matches.competitors[0].name:matches.competitors[1].name}}</span>
                                        </h5>
                                    </div>
                                    <div class="live_center_fixtured_result">
                                        <h6 class="desktop_tooltip ftb_match_ft"
                                            v-if="matches.status == 'Result Only' || matches.status == 'NSY' || matches.status == 'Abandoned'|| matches.status == 'No Info Yet'|| matches.status == 'Cancelled'|| matches.status == 'Postponed'|| matches.status == 'Awarded' || matches.status == 'Delayed'">
                                            {{matches.date| formatDate('hh:mm')}}</h6>
                                        <h6 class="desktop_tooltip ftb_match_ft"
                                            v-if="matches.status == '1st Half' || matches.status == 'Half Time' || matches.status == '2nd Half' || matches.status == 'inprogress'">
                                            {{matches.currentFullScore}}</h6>
                                        <h6 class="desktop_tooltip ftb_match_ft"
                                            v-if="matches.status == 'Finished' || matches.status == 'Full Time'">
                                            {{matches.fullTime}}</h6>
                                        <h6 class="desktop_tooltip ftb_match_ft"
                                            v-if="matches.status == 'Finished AP'  || matches.status == 'Finished AET'">
                                            {{matches.extraTime}}</h6>
                                        <h6 class="desktop_tooltip ftb_match_ft"
                                            v-if="matches.status == 'ET 1st Half' || matches.status == 'ET 2nd Half' || matches.status == 'Penalty' || matches.status == 'inprogress'">
                                            {{matches.extraTime}}</h6>
                                    </div>
                                    <div class="live_center_fixtured_name">
                                        <h5>
                                            <span class="fixtured_away_team_name">{{matches.competitors[0].isHome==false?matches.competitors[0].name:matches.competitors[1].name}}</span>
                                            <span class="fixtured_away_team_name_mobile">{{matches.competitors[0].isHome==false?matches.competitors[0].name:matches.competitors[1].name}}</span>
                                        </h5>
                                    </div>
                                </router-link>
                            </div>
                            <div class="live_center_fixtured_tvlogo"></div>
                            <div class="live_center_fixtured_mic"></div>
                            <div class="live_center_fixtured_fav" title="Add to my matches">
                                <i :class="($store.getters.isFbMatchFavourited(matches.id)) ? 'text-green' : '1'" class="fa fa-star" @click="toggleFavourite(matches.id)"></i>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="competitions_cards_score">
            </div>
        </div>
        <div v-else>
            <p style="text-align: center;font-size: 20px;font-weight: 500;">No Fixtures on this day!</p>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'LeagueListing',
        props: ['previous_date', 'future_date', 'tournament_detail'],
        data() {
            return {
                url: '',
                tournament: [],
            }
        },
        mounted() {
            this.getMatchLeagueDetail();
        },
        serverPrefetch() {
            return this.getMatchLeagueDetail();

        },
        watch: {
            $route(to, from) {
                this.getMatchLeagueDetail();
            }
        },
        methods: {
            getMatchLeagueDetail() {
                if (this.$route.params.date != '') {
                    this.url = this.$FB_MATCHES_API + '?tournamentId='+this.$route.params.leagueId+'&date=' + this.$route.params.date;
                } else {
                    this.url = this.$FB_MATCHES_API + '?tournamentId='+this.$route.params.leagueId;
                }
                return this.$axios.get(this.url)
                    .then(response => {
                        this.tournament = response.data.tournaments;
                    })
                    .catch(error => console.log(error))
            },
            toggleFavourite(id) {
                this.$store.commit(this.$store.getters.isFbMatchFavourited(id) ? 'removeFootballFavouriteMatch' : 'addFootballFavouriteMatch', id);
                if (this.$route.name == 'football.myMatches') {
                    var match_list = this.tournaments;
                    var match_id = id;
                    this.tournaments.forEach(function (val, key) {
                        val.matches.forEach(function (val_a, key_a) {
                            //if given match id exists in matchList.matches array then remove that match
                            if(val_a.id == id){
                                val.matches.splice(key_a, 1);
                            }
                            //if given matchList.matches contain no match then remove that record(contest group data) from matchList
                            if (val.matches.length < 1){
                                match_list.splice(key, 1);
                            }
                        });
                    });
                }
            },
        }
    }
</script>
